// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-build-to-rent-js": () => import("./../../../src/pages/build-to-rent.js" /* webpackChunkName: "component---src-pages-build-to-rent-js" */),
  "component---src-pages-co-working-js": () => import("./../../../src/pages/co-working.js" /* webpackChunkName: "component---src-pages-co-working-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact-form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-network-and-enterprise-js": () => import("./../../../src/pages/data-network-and-enterprise.js" /* webpackChunkName: "component---src-pages-data-network-and-enterprise-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-environments-js": () => import("./../../../src/pages/internet-environments.js" /* webpackChunkName: "component---src-pages-internet-environments-js" */),
  "component---src-pages-our-network-js": () => import("./../../../src/pages/our-network.js" /* webpackChunkName: "component---src-pages-our-network-js" */),
  "component---src-pages-public-wifi-js": () => import("./../../../src/pages/public-wifi.js" /* webpackChunkName: "component---src-pages-public-wifi-js" */),
  "component---src-pages-residential-fibre-network-js": () => import("./../../../src/pages/residential-fibre-network.js" /* webpackChunkName: "component---src-pages-residential-fibre-network-js" */),
  "component---src-pages-software-platform-js": () => import("./../../../src/pages/software-platform.js" /* webpackChunkName: "component---src-pages-software-platform-js" */),
  "component---src-pages-student-accommodation-js": () => import("./../../../src/pages/student-accommodation.js" /* webpackChunkName: "component---src-pages-student-accommodation-js" */)
}

